import React, { Component } from 'react';
import queryString from 'query-string';
import { navigate } from "gatsby";
import { getBaseURL,decodeUserToken } from '../../helper/getApiBase';

export default class Redirect extends Component {

    componentDidMount() {
        const parsed = queryString.parse(window.location.search);
        if (parsed.token && parsed.path) {
            sessionStorage.clear();
            sessionStorage.setItem('token', parsed.token);
            if (sessionStorage.token) {
                this.checkForRegion(sessionStorage.token);
            }
            if (parsed.unsubscription_type) {
                sessionStorage.setItem('unsubscription_type', parsed.unsubscription_type);
            }
            if (parsed.manager_access_id) {
                sessionStorage.setItem('manager_access_id', parsed.manager_access_id);
            }
            if (parsed.role) {
                sessionStorage.setItem('role', parsed.role)
            }
            if (parsed.role) {
                sessionStorage.setItem('view_type', parsed.view_type)
            }
            if (parsed.email) {
                sessionStorage.setItem('email', parsed.email)
            }
            navigate(`/${parsed.path}`);
        } else {
            navigate("/");
        }

    }

    checkForRegion = async (token) => {
        sessionStorage.removeItem("current_region_url");
        let profile = await decodeUserToken();
        await getBaseURL(profile?.region, "dashboard");
    }

    render() {
        return (
            <div className="pd-80">
                <p className="has-text-centered">Redirecting...</p>
            </div>
        )
    }
}


/* Active Redirect URL's

1. https://infeedo.com/redirect/?token=<token>&path=password-reset
2. https://infeedo.com/redirect/?token=<token>&path=unsubscribe&unsubscription_type=unsubscribe
3. https://infeedo.com/redirect/?token=<token>&path=unsubscribe&unsubscription_type=unsubscribe_celebration_email
4. https://infeedo.com/redirect/?token=<token>&path=unsubscribe&unsubscription_type=unsubscribe_fortnightly
5. https://infeedo.com/redirect/?token=<token>&path=unsubscribe&unsubscription_type=unsubscribe_weekly_report&manager_access_id=<id>
6. https://infeedo.com/redirect/?token=<token>&path=unsubscribe&unsubscription_type=unsubscribe_quarterly_report&manager_access_id=<id>
7. https://infeedo.com/redirect/?token=<token>&path=database-upload-secure

*/